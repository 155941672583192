@import '~@angular/material/theming';

@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap');

$font-Family : 'Open Sans';

$fontConfig: (
  display-4: mat-typography-level(112px, 112px, 400, $font-Family, -0.0134em),
  display-3: mat-typography-level(56px, 56px, 400, $font-Family, -0.0089em),
  display-2: mat-typography-level(45px, 48px, 400, $font-Family, 0.0000em),
  display-1: mat-typography-level(34px, 40px, 400, $font-Family, 0.0074em),
  headline: mat-typography-level(24px, 32px, 400, $font-Family, 0.0000em),
  title: mat-typography-level(20px, 32px, 400, $font-Family, 0.0075em),
  subheading-2: mat-typography-level(16px, 28px, 400, $font-Family, 0.0094em),
  subheading-1: mat-typography-level(15px, 24px, 400, $font-Family, 0.0067em),
  body-2: mat-typography-level(14px, 24px, 400, $font-Family, 0.0179em),
  body-1: mat-typography-level(14px, 20px, 400, $font-Family, 0.0179em),
  button: mat-typography-level(14px, 14px, 400, $font-Family, 0.0893em),
  caption: mat-typography-level(12px, 20px, 400, $font-Family, 0.0333em),
  input: mat-typography-level(inherit, 1.125, 400, $font-Family, 1.5px)
);

// Foreground Elements

// Light Theme Text
$dark-text: #223343;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config

// Light bg
$light-background: #ffffff;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#223343, 20%);
$dark-bg-alpha-4: rgba(#223343, 0.04);
$dark-bg-alpha-12: rgba(#223343, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #223343;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#ffffff, 0.04);
$light-bg-alpha-12: rgba(#ffffff, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $light-background,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
  sidenav: $light-background
);

// Compute font config
@include mat-core($fontConfig);

// Theme Config

body {
  --primary-color: #223343;
  --primary-lighter-color: #bdc2c7;
  --primary-darker-color: #14202c;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}

$mat-primary: (
  main: #223343,
  lighter: #bdc2c7,
  darker: #14202c,
  200: #223343,
  // For slide toggle,
  contrast : (main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-primary: mat-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #2a3f54;
  --accent-lighter-color: #bfc5cc;
  --accent-darker-color: #19293a;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}

$mat-accent: (
  main: #2a3f54,
  lighter: #bfc5cc,
  darker: #19293a,
  200: #2a3f54,
  // For slide toggle,
  contrast : (main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-accent: mat-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: #ff0000;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0000;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (
  main: #ff0000,
  lighter: #ffb3b3,
  darker: #ff0000,
  200: #ff0000,
  // For slide toggle,
  contrast : (main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-warn: mat-palette($mat-warn, main, lighter, darker);

$theme: mat-dark-theme($theme-primary, $theme-accent, $theme-warn);
$altTheme: mat-light-theme($theme-primary, $theme-accent, $theme-warn);

// Theme Init
.ligth-theme {
  @include angular-material-theme($altTheme);

  .mat-toolbar {
    background: #ffffff;
  }

  .mat-button-toggle-group-appearance-standard {
    border: none;

    .mat-button-toggle+.mat-button-toggle {
      border: none;
    }
  }

  .mat-button-toggle-appearance-standard {
    background: #ffffff;
  }

  .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    background: var(--selectButton);
  }

  .mat-table {
    background: var(--primary);
  }

  .mat-row:nth-child(odd) {
    background-color: var(--selectButton);
  }

  .mat-paginator {
    background: var(--primary);
  }

  .mat-input-element {
    color: var(--fontPrimaryColor);
  }

  .mat-form-field-appearance-legacy .mat-form-field-label {
    mat-label {
      color: var(--fontPrimaryColor);
    }
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: var(--fontPrimaryColor);
  }

  .mat-menu-panel {
    background: var(--secundary);

    .mat-icon-no-color {
      color: var(--fontPrimaryColor)
    }
  }

  .mat-dialog-container {
    background-color: var(--secundary);
  }

  .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
    color: rgba(34, 51, 67, 0.5);
  }
}

//@include angular-material-theme($theme);

.theme-dark {
  @include angular-material-theme($theme);

  .mat-header-cell {
    font-weight: 900;
  }

  .mat-toolbar {
    background: var(--primary);
  }

  .mat-button-toggle-group-appearance-standard {
    border: none;

    .mat-button-toggle+.mat-button-toggle {
      border: none;
    }
  }

  .mat-button-toggle-appearance-standard {
    background: var(--primary);
  }

  .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    background: var(--selectButton);
  }

  .mat-table {
    background: var(--primary);
  }

  .mat-paginator {
    background: var(--primary);
  }

  .mat-input-element {
    color: var(--fontPrimaryColor);
  }

  .mat-form-field-appearance-legacy .mat-form-field-label {
    mat-label {
      color: var(--fontPrimaryColor);
    }
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: var(--fontPrimaryColor);
  }

  .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: var(--fontPrimaryColor) !important;
  }

  .mat-input-element {
    caret-color: var(--fontPrimaryColor) !important;
  }

  .mat-form-field-label {
    color: var(--fontPrimaryColor) !important;
  }

  .mat-raised-button.mat-accent {
    background-color: var(--accentBlue);
  }

  .mat-dialog-container {
    background-color: var(--primary);
  }

  .mat-card {
    background-color: var(--primary);
  }

  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: var(--fontPrimaryColor);
  }
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 .65em;
  min-width: 3em;
  line-height: 36.4px
}

.mat-standard-chip {
  padding: .5em .85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';

  .mat-badge-content {
    font-family: 'Open Sans';
  }
}

// mat-input error outline color
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: red !important;
  opacity: 0.8 !important;
}

// mat-label error style
.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: red !important;
}