/* _________________ Colors _________________ */

// Font-Family
$font-Family : 'Open Sans';

:root{
  --primary: #223343;
  --accent: #E7E7E7;
  --secundary: #2A3F54;
  --accentBlue: #0B1E31;
  --fontPrimaryColor: #FFFFFF;
  --fontAccentColor: #223343;
  --bgForms: #3B4E62;
  --bgTables: #FFFFFF;
  --bgTablesSecundary: rgba(59,78,98,0.25);
  --fontTables:#595959;
  --alerts:#F45C00;
  --red: #FF0000;
  --orange: #F58009;
  --aquamarine: #57F4FF;
  --green: #00E676;
  --activeBotton:#57F4FF;
  --selectButton: #3B4E62;
  --fontFamily : 'Open Sans';
  --bgInput: #3B4E62;
	--bgBorderInput: #ffffff;
	--colorLabelInput: #ffffff;
	--colorInput: #ffffff;
  --bgModal: #2a3f54;
	--bgCard: #223343;
	--bgSubmenuModal: #2a3f54;
	--colorSubMenuModal: #ffffff;
	--bgSubmenuModalActive: #354E62;
	--colorSubMenuModalActive: #0b1e31;
  --bgTitleTables: #595959;
	--colorTitleTable: #ffffff;
  --bgContainerInfo:#223343;
}
