

.w-100{
    width: 100% !important;
    @include mq-max(medium) {
      &-down--sm {
        width: 100% !important;
      }
    }
  }

  .second-table-container{
    //background-color: #1f2937;
    padding: 5px;
    border-radius: 10px;
    /*table , mat-paginator{
      background-color: #1f2937;
    }*/

  }


.pointer-none {
  pointer-events: none;
}

.bg{
  &-gray{
      background-color: #ccc;
  }
  &-green{
    background-color: #4cb050;
  }
  &-blue{
    background-color: #2196f3;
  }
  &-dark-gray{
    background-color: #9e9e9e;
  }
  &-red{
    background-color: #b61c1c;
  }
  &-skin{
    background-color: #ff9700;
  }
}


.add-image{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 1px var(--fontPrimaryColor) dashed;
  height: 200px;
}

.images{
  position: relative;
  display: flex;
  justify-content: center;
  height: 200px;
  &__delete{
     position: absolute;
     right :-10px;
     top: -10px;
     z-index: 10;
     cursor : pointer;
  }
  &__img{
     width: 100%;
     height: 100%;
  }
}
