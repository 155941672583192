/* Archivo con variables globales: */
@import "./assets/css/angular-calendar.css";
@import "./importations.scss";

/* You can add global styles to this file, and also import other style files */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "assets/scss/var-mixins", "assets/scss/general", "assets/scss/showItem";

* {
  scrollbar-width: thin;
  scrollbar-color: rgba(67, 67, 196, 0.459) #dbdfea;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: #dbdfea;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(67, 67, 196, 0.459);
  border-radius: 20px;
  border: 3px solid #dbdfea;
}


.mat-button-toggle-button:focus {
  outline: none;
}

.font-OpenSans {
  font-family: $font-Family, sans-serif !important;
}

/* .leaflet-control-layers {
  margin-right: 35%;
  margin-top: 60%;
}
.leaflet-control-layers-expanded {
  margin-top: 60%;
}

.leaflet-control {
}
 */
button:focus {
  outline: none;
}

/* Tooltip Styles */

.LostSignal {
  background: #686868;
  color: #000000;
}

.personaAsociada_tooltip {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: blue;
  color: white;
  text-align: center;
}

.leaflet-popup-content-wrapper {
  background: rgba(0, 0, 0, 0) !important;
  border: none !important;
  font-size: 15px;
  text-align: left;
  box-shadow: none !important;
}

.leaflet-popup-content {
  color: white;
  margin: 5px 0px 5px 8px;
}

.leaflet-popup-tip {
  background: rgba(0, 0, 0, 0) !important;
  box-shadow: none !important;
}

//Botones del mapa principal
.leaflet-control-zoom.leaflet-bar.leaflet-control {
  margin-bottom: 8%;
}

.leaflet-control-layers.leaflet-control {
  margin-bottom: 120%;
}

.leaflet-control-layers.leaflet-control.leaflet-control-layers-expanded {
  margin-bottom: 8%;
}


.leaflet-bar.easy-button-container.leaflet-control {
  height: 35px;
}

.leaflet-top {
  display: none;
}



.tooltipOnClick {
  background-color: #243344;
}

#popupContentCoords {
  font-size: 13px;
}


.colorTest1 {
  fill: rgb(0, 18, 183)
}

mat-header-cell {
  font-weight: bold;
}

mat-header-cell,
mat-cell {
  display: flex;
  justify-content: center;
  text-align: center;
}

mat-cell>span.truncate-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}